/// <reference path="o365.pwa.declaration.shared.dexie.objectStores.ServiceWorkerState.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

import type * as ServiceWorkerStateModule from 'o365.pwa.declaration.shared.dexie.objectStores.ServiceWorkerState.d.ts';

import type { ServiceWorkerScriptState } from 'o365.pwa.declaration.shared.dexie.objectStores.ServiceWorkerScriptState.d.ts';
import type { AppResourceState } from 'o365.pwa.declaration.shared.dexie.objectStores.AppResourceState.d.ts';
import type { IServiceWorkerImportMap } from 'o365.pwa.declaration.sw.IServiceWorkerImportmap.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const indexedDBHandlerImportmapEntry = self.o365.getImportMapEntryFromImportUrl('o365.pwa.modules.sw.IndexedDBHandler.ts');

    class ServiceWorkerState implements ServiceWorkerStateModule.ServiceWorkerState {
        static objectStoreDexieSchema: string = "&appId";

        appId!: string;
        requireServiceWorkerUpdate!: boolean;
        requiredServiceWorkerScripts!: Array<string | URL>;
        entrypoint: string;
        importMap: IServiceWorkerImportMap;
        readyForInstall: boolean;
        installed: boolean;
        failedToInstall: boolean;

        public get serviceWorkerScriptStates() {
            const serviceWorkerState = this;

            return new Proxy<ServiceWorkerStateModule.ServiceWorkerScriptStates>(<ServiceWorkerStateModule.ServiceWorkerScriptStates>{
                getAll: async () => {
                    const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);
                    
                    return await IndexedDBHandler.getServiceWorkerScriptStates(serviceWorkerState.appId);
                }
            }, {
                get(target, prop, receiver) {
                    if (prop in target) {
                        return Reflect.get(target, prop, receiver);
                    }

                    return new Promise<ServiceWorkerScriptState | null>(async (resolve, reject) => {
                        try {
                            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

                            const serviceWorkerScriptState = await IndexedDBHandler.getServiceWorkerScriptState(serviceWorkerState.appId, prop.toString());

                            resolve(serviceWorkerScriptState);
                        } catch (reason) {
                            reject(reason);
                        }
                    });
                }
            });
        }

        public get appResourceStates() {
            const serviceWorkerState = this;

            return new Proxy<ServiceWorkerStateModule.AppResourceStates>(<ServiceWorkerStateModule.AppResourceStates>{
                getAll: async () => {
                    const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

                    return await IndexedDBHandler.getAppResourceStates(serviceWorkerState.appId);
                }
            }, {
                get(target, prop, receiver) {
                    if (prop in target) {
                        return Reflect.get(target, prop, receiver);
                    }

                    return new Promise<AppResourceState | null>(async (resolve, reject) => {
                        try {
                            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

                            const appResourceState = await IndexedDBHandler.getAppResourceState(serviceWorkerState.appId, prop.toString());

                            resolve(appResourceState);
                        } catch (reason) {
                            reject(reason);
                        }
                    });
                }
            });
        }

        constructor(options: ServiceWorkerStateModule.IServiceWorkerStateOptions) {
            this.appId = options.appId;
            this.entrypoint = options?.entrypoint ?? "o365.pwa.modules.sw.DefaultEntryPoint.ts";
            this.importMap = options.importMap ?? {};
            this.readyForInstall = false;
            this.installed = false;
            this.failedToInstall = false;
        }

        public async save(): Promise<void> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

            await IndexedDBHandler.updateServiceWorkerState(this);
        }

        public async delete(): Promise<void> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);
            
            await IndexedDBHandler.deleteServiceWorkerState(this);
        }

        public async forceReload(): Promise<ServiceWorkerState | null> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);
            
            return await IndexedDBHandler.getServiceWorkerStateFromIndexedDB(this.appId);
        }

        static clearCache(): void {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);
            
            IndexedDBHandler.clearServiceWorkerStateCache();
        }
    }

    self.o365.exportScripts({ ServiceWorkerState });
})();
